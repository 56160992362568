import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default function CardView(props) {
  const { topic, extraText, imgPath, imgTitle, viewText, technologiesUsed, reRenderVanta } = props;
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current) {
      window.scrollTo(0, ref.current.getBoundingClientRect().top + window.scrollY - 200);
    }
    reRenderVanta();
  }, []);

  return (
        <Grow in={true} ref={ref}>
        <Grid
        container spacing={4}>
        <Grid item xs={5}>
        <CardMedia
            sx={{ height: "600px", objectFit: "cover"}}
            elevation={0}
            src={imgPath}
            component="video"
            title={imgTitle}
            draggable={false}
            autoPlay
            loop={true}
        />
        </Grid>
        <Grid
          xs={6.75}
          item
          container
          direction="column"
          justifyContent={"space-between"}
        >
          <Grid item>
          <CardContent>
            <Typography
            sx={{fontSize: {
                    lg: 24,
                    md: 22,
                    sm: 20,
                    xs: 16
              }}}
              align='left' component="div" color="#0b5099">
              {topic}
            </Typography>
            <Typography          
            sx={{fontSize: {
                    lg: 14,
                    md: 13,
                    sm: 12,
                    xs: 12
              }}}
              fontStyle='italic' variant="body2" align='left' color="#0b5099">
              {extraText}
            </Typography><br></br>
            <Typography 
            sx={{fontSize: {
                        lg: 15,
                        md: 14,
                        sm: 13,
                        xs: 12
              }}} 
              variant="body1" align='left' color="#0b5099" textAlign={"justify"}>
              {viewText}
            </Typography>
          </CardContent>
          </Grid>
            <Grid marginBottom={'5%'} item>
            <Stack spacing={{ xs: .5, sm: 1 }}
              direction="row"
              useFlexGap
              marginLeft={"16px"}
              sx={{ flexWrap: 'wrap' }}
            >
              {technologiesUsed.split(", ").map((e) => {
                return <Box padding={.5} sx={{borderRadius: '16px', border: 1, backgroundColor: "white", borderColor: "#0b5099"}}>          
                  <Typography 
                  sx={{fontSize: {
                            lg: 15,
                            md: 14,
                            sm: 13,
                            xs: 12
                  }}}
                  variant="body1" align='left' color="#0b5099" marginLeft={0.5} marginRight={0.5}>
                    {e}
                  </Typography>
                </Box>       
              })}
            </Stack>
            </Grid>
          </Grid>
          </Grid>
        </Grow>);
}